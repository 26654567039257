@import '../../../../styles/variables/typography.scss';
@import '../../../../styles/variables/colors.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 30px;

  .topPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .title {
      text-align: center;
      font-size: $fontLarge;
      font-family: $fontWeightBold;
    }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      color: $walletConnectBlue;
    }

    .description {
      font-size: $fontMedium;
      text-align: center;

      .address {
        font-family: $fontWeightBold;
      }
    }

    .infoEntry {
      display: flex;
      font-size: $fontSize;
      color: $mainGrey;
      gap: 8px;
      justify-content: start;
      width: 100%;
      align-items: center;

      .infoIcon {
        width: 20px;
        height: 20px;
      }

      .text {
        font-size: $fontSize;
        max-width: calc(100% - 28px);
      }
    }
  }

  .bottomPart {
    display: flex;
    flex-direction: column;
  }
}