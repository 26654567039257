// font sizes

$fontTiny: 10px;
$fontVerySmall: 12px;
$fontSize: 14px;
$fontMedium: 16px;
$fontSemiLarge: 17px;
$fontLarge: 18px;
$fontTitle: 19px;
$fontVeryBig: 23px;
$fontHuge: 28px;
$fontGigantic: 40px;

// font weights

@font-face {
  font-family: helveticaNowRegular;
  src: url('../fonts/helvetica-now-font-regular/font.woff');
}

@font-face {
  font-family: helveticaNowBold;
  src: url('../fonts/helvetica-now-font-bold/font.woff');
}

@font-face {
  font-family: helveticaNowMedium;
  src: url('../fonts/helvetica-now-font-medium/font.woff');
}

@font-face {
  font-family: helveticaNowXBold;
  src: url('../fonts/helvetica-now-font-xbold/font.woff');
}

//font-weight: 800
$fontWeightBold: helveticaNowXBold;
//font-weight: 700
$fontWeightSemiBold: helveticaNowBold;
//font-weight: 500
$fontWeightRegular: helveticaNowMedium;