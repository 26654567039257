@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/typography';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .mainContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .heading {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .title {
        display: flex;
        width: 100%;
        justify-content: center;
        color: $mainBlack;
        text-align: center;
        font-family: $fontWeightBold;
        font-size: $fontLarge;
      }

      .explanation {
        display: flex;
        justify-content: center;
        color: $fontSecondary;
        text-align: center;
        font-family: $fontWeightRegular;
        font-size: $fontMedium;
      }
    }

    .formInput {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .label {
        color: $fontPrimary;
        font-size: $fontSize;
        font-family: $fontWeightRegular;
        line-height: 140%;
        letter-spacing: 0.14px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;

    .iconContainer {
      width: 20px;
      height: 20px;
      color: $primaryPurple;
    }

    .text {
      color: $fontSecondary;
      font-family: $fontWeightRegular;
      font-size: $fontSize;
    }
  }
}