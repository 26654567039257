@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.main-button {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 18px;
  letter-spacing: 1px;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: $fontSize;
  cursor: pointer;
  min-width: 300px;
  padding: 14px 28px;

  &.confirm-button {
    background-color: $primaryPurple;
    color: $fontWhite;

    .bolt-icon {
      margin-right: 10px;
      width: 23px;
      height: 23px;
    }

    .wallet-connect-icon {
      width: 28px;
      margin-right: 10px;
      color: $mainGreen;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $secondaryDisabledGrey;
    }
  }

  &.icon-button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    min-width: unset;

    &:disabled {
      visibility: hidden;
    }
  }

  &.secondary-button {
    background-color: $lightPurple;
    color: $fontPurple;
  }

  &.download-button {
    background-color: $mainYellow;
    color: $fontPrimary;
    font-family: $fontWeightSemiBold;

    .download-icon {
      margin-right: 10px;
      width: 23px;
      height: 23px;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $secondaryDisabledGrey;
    }
  }

  .qr-code-icon {
    margin-right: 10px;
  }
}

.button-3d {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  transition: background-color 200ms ease-in;

  &:not(:disabled):hover {
    transform: translateY(-1px);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.12);
  }

  &:active {
    transform: translateY(2px);
    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.1), inset 0px 1px 3px rgba(0, 0, 0, 0.08);
  }
}
