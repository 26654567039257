@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.transfer-assets {
  margin-top: 30px;
}

.withdraw-pk-default-container {
  padding-inline: 18px;

  .mnemonic-confirmation-container {
    display: flex;
    padding: 26px 20px;
    flex-direction: column;
    align-items: center;
    gap: 18px;

    .mnemonic-confirmation-title-container {
      display: flex;
      align-items: center;
      gap: 7px;

      .mnemonic-confirmation-icon {
        width: 36px;
        height: 36px;
      }

      .mnemonic-confirmation-title {
        color: $fontPrimary;
        font-family: $fontWeightSemiBold;
        font-size: $fontLarge;
      }
    }

    .mnemonic-confirmation-button-text {
      font-family: $fontWeightSemiBold;
      font-size: $fontSize;
    }
  }
}

.withdraw-pk-default-main-container {
  padding: 26px 20px;
}

.withdraw-modal-title {
  text-align: center;
  font-size: $fontVeryBig;
  color: $fontPrimary;
  margin: 15px 0 10px 0;
  font-family: $fontWeightBold;
}

.withdraw-modal-info {
  color: $fontSecondary;
  text-align: center;
  font-size: $fontMedium;
  font-family: $fontWeightRegular;
}

.withdraw-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;

  .qr-scan-link-container {
    display: flex;
    align-items: center;
    gap: 6px;
    color: $primaryPurple;

    .qr-scan-icon {
      width: 26px;
      height: 26px;
    }

    .qr-scan-link {
      font-family: $fontWeightRegular;
      font-size: $fontMedium;
      text-decoration-line: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.withdraw-intro-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding-inline: 40px;
  text-align: start;
  padding-bottom: 30px;

  .withdraw-content-item-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 17px;

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      color: $primaryPurple;
    }

    .text-container {
      display: flex;
      flex-direction: column;

      .item-title {
        width: 100%;
        color: $fontPrimary;
        font-size: $fontMedium;
        font-family: $fontWeightSemiBold;
      }

      .item-information {
        width: 100%;
        color: $fontSecondary;
        font-size: $fontSize;
        font-family: $fontWeightRegular;
      }
    }
  }
}

.header-unmargined {
  margin-top: -30px;
}

.mnemonic-input-modal-content {
  width: 628px;
  height: 610px;
  border-radius: 20px;
  background: $backgroundWhite;
  box-shadow: 0 54px 80px 0 rgba(0, 0, 0, 0.10);
}

.mnemonic-input-modal-body {
  gap: 20px;
  padding: 11px 52px 0 52px;

  .mnemonic-input-modal-title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .mnemonic-modal-title {
      color: $fontPrimary;
      text-align: center;
      font-family: $fontWeightSemiBold;
      font-size: $fontVeryBig;
    }

    .mnemonic-modal-information {
      color: $fontSecondary;
      text-align: center;
      font-family: $fontWeightRegular;
      font-size: $fontMedium;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

.single-word-input-container {
  display: flex;
  width: 150px;
  height: 40px;
  padding: 2px 12px 2px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 13px;
  border: 0.8px solid $borderGray;
  background: $backgroundWhite;
  backdrop-filter: blur(47px);

  .index-container {
    color: $fontPrimary;
    font-family: $fontWeightSemiBold;
    font-size: $fontSemiLarge;
    width: 30px;
  }

  .word-input {
    width: 100px;
    height: 100%;

    .input-suggestion {
      position: absolute;
      pointer-events: none;
      border: none;
      outline: none;
      width: 90px;
      height: 100%;
      color: $fontSecondary;
      box-sizing: border-box;
      background-color: transparent;
    }

    .small-input {
      position: absolute;
      border: none;
      outline: none;
      width: 90px;
      height: 100%;
      color: $fontPrimary;
      box-sizing: border-box;
      background-color: transparent;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 7px;
}

.mnemonic-words-inputs-container {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  margin-top: 20px;
}

.no-margin {
  margin-top: 0 !important;
}

.qr-screen-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 30px;

  .qr-heading {
    width: 229px;
    color: $fontPrimary;
    text-align: center;
    font-family: $fontWeightSemiBold;
    font-size: $fontVeryBig;
    margin-top: 40px;
  }

  .qr-reader {
    width: 100%;
    display: inline-flex;
    padding: 19px;
    margin-top: 36px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 23px;
    border: 5px solid $mainPurple;
  }

  .qr-error {
    color: $fontError;
    font-size: $fontSize;
    padding-top: 16px;
  }

  .qr-info {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    margin-top: 30px;

    .qr-info-icon {
      width: 24px;
      height: 24px;
    }

    .qr-info-text {
      color: $fontPrimary;
      font-family: $fontWeightRegular;
      font-size: $fontSemiLarge;
    }
  }
}

.btn-margin-top {
    margin-top: 18px;
}

.amount-input {
  margin-bottom: 16px
}