@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/typography';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: $fontLarge;

  .providerName {
    text-transform: capitalize;
    font-family: $fontWeightSemiBold;
  }
}