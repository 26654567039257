@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.modal-intro-header {
  margin-bottom: 39px;

  .modal-intro-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  .modal-intro-title {
    text-align: center;
    font-size: $fontVeryBig;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: $fontPrimary;
    margin: 15px 0 0 0;
    font-family: $fontWeightBold;
  }
}

.modal-intro-descriptions-container {
  margin: 0 43px 49px 44px;

  .modal-intro-description-content {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px 0;

    &.savings-intro-description-content {
      flex-direction: row;
    }

    &:last-child {
      margin: 0;
    }

    .modal-intro-description-title {
      display: flex;
      flex-direction: row;

      .icon {
        width: 24px;
        height: 24px;
        color: #221B38;
      }

      &.crypto-savings-intro-title {
        flex-direction: column;
      }

      .intro-description-title {
        margin: 0 0 0 17px;
        font-family: $fontWeightSemiBold;
        font-size: $fontLarge;
        line-height: 23px;
        letter-spacing: -0.01em;
        color: $fontPrimary;
      }
    }

    .modal-intro-description {
      margin: 4px 0 0 0;

      .intro-description {
        margin: 0 0 0 41px;
        font-family: $fontWeightRegular;
        font-size: $fontMedium;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: $fontSecondary;
      }
    }
  }
}

.exit-modal {
  position: relative;
  margin: 28px 0;
  left: 40%;
  cursor: pointer;
}