@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.crypto-savings {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px 33px 24px;
  width: 100%;
  height: 100%;

  .Mui-selected {
    background-color: $lightPurple!important;
    color: $mainPurple!important;
    font-weight: 700!important;
  }

  .confirm-button:disabled {
    cursor: not-allowed;
    background-color: #E3E3E3;
    color: $fontSecondary;
    svg {
      path {
        fill: $fontSecondary;
      }
    }
  }

  .main-input {
    font-size: $fontLarge;
    font-family: $fontWeightSemiBold;
    max-width: 180px;

    &::placeholder {
      font-family: $fontWeightRegular;
    }
  }

  .select-input-container {
    display: flex;
    align-items: center;

    .select-input {
      font-size: $fontLarge;
      color: $fontPrimary;
      width: 55px;
    }
  }

  .create-saving-title {
    margin: 20px 47px 28px 46px;
    text-align: center;
    color: $fontPrimary;
    font-size: $fontVeryBig;
    font-family: $fontWeightSemiBold;
    line-height: 29px;
    letter-spacing: -0.23px;
  }

  .inputs-container {
    display: flex;
    gap: 16px;
    flex-direction: column;
    width: 100%;
    margin-bottom: auto;

    .crypto-savings-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 28px;
      border-radius: 10px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.20);
      font-family: $fontWeightSemiBold;

      .input-label {
        color: $fontPrimary;
        font-size: $fontLarge;
        font-family: $fontWeightSemiBold;
        letter-spacing: -0.18px;
      }

      &.read-only {
        border-radius: 0;
        border-top: #E9E9E9 solid 2px;
        box-shadow: none;

        &:first-of-type {
          border-top: none;
        }
      }

      .select {
        .select-input-container {
          border: none;
          min-width: 65px;
          max-width: 65px;
          padding: 0;
          margin-bottom: 0;

          .select-input {
            color: $fontPrimary;
            font-size: $fontLarge;
            font-family: $fontWeightSemiBold;
            letter-spacing: -0.18px;
          }
        }
      }

      .main-input {
        min-width: unset;
        padding: 0;
        border: none;
        text-align: right;

        &:focus-visible {
          outline: none;
          border-radius: 0;
        }
      }

      .calendar-frequency-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: $fontSecondary;
        font-size: $fontSize;
        background: none;
        border: none;
        cursor: default;

        .calendar-icon {
          width: 20px;
          height: 20px;
        }

        .frequency-text {
          font-size: $fontSize;
        }
      }
    }
  }
}