@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.security-words {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .main-input {
    width: 140px;
    min-width: unset;
  }

  .security-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0 45px;
    margin: 20px 0 29px;
  }

  .security-title {
    font-family: $fontWeightSemiBold;
    font-size: $fontVeryBig;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: $fontPrimary;
    text-align: center;
  }

  .security-description {
    font-size: $fontMedium;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: $fontSecondary;
    text-align: center;
    font-family: $fontWeightRegular;
  }

  .security-inputs {
    display: flex;
    flex-direction: column;
    padding: 0 45px;
    margin-bottom: 40px;

    .input-element {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 18px;
      font-family: $fontWeightRegular;
      font-size: $fontMedium;
      margin-bottom: 14px;

      .main-input {
        width: 100%;
        text-align: center;
        padding: 14px 0;
      }
    }
  }

  .button {
    display: flex;
    justify-content: center;
  }

  .download-button {
    margin-bottom: 33px;
  }
}