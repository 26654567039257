@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/typography';

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  gap: 16px;

  .title {
    color: $fontPrimary;
    text-align: center;
    font-family: $fontWeightSemiBold;
    font-size: $fontVeryBig;
  }

  .description {
    max-width: 260px;
    text-align: center;
    color: $fontSecondary;
    font-family: $fontWeightRegular;
    font-size: $fontMedium;
  }
}