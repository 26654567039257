@import 'src/styles/variables/colors';

.savings-intro {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 45px;

  .modal-intro-description-content {
    margin: 0;

    .modal-intro-description {
      margin: 0;
    }
  }

  .icon-container {
    width: 30px;
    height: auto;
    min-width: 30px;
    display: flex;
    color: $primaryPurple;
  }

  .savings-intro-description {
    margin-left: 17px;
  }
}

