// Background colors
$primaryPurple: #7346f3;
$borderGray: #D6D2D2;
$backgroundWhite: #FFFFFF;
$disabledGrey: #EFEFEF4D;
$secondaryDisabledGrey: #a4a4a4;
$lightGreen: #D6FFE1;
$borderLightGrey: #E7E7E7;
$errorBackground: #FACECA;

// Font colors
$fontPrimary: #202020;
$fontSecondary: #888888;
$fontPurple: #7346F3;
$fontWhite: #FFFFFF;
$fontError: #EF032D;
$titleColor: #2F2D3E;
$fontGreen: #008F64;

$mainBlack: #202020;
$mainGrey: #888888;
$lightGrey: #D2D2D2;
$mainWhite: #FFFFFF;
$mainPurple: #7346f3;
$lightPurple: #EAE4FD;
$mainGreen: #6dff96;
$mainYellow: #FFD66D;
$successColor: #047802;
$errorColor: #D40202;
$infoColor: #0223f7;
$secondaryRed: #FF4A6B;
$walletConnectBlue: #3396FF;
