@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/typography';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 30px;
  padding-inline: 30px;

  .topContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .headingContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      text-align: center;

      .titleContainer {
        display: flex;
        flex-direction: column;
        color: $fontPrimary;
        text-align: center;
        font-size: $fontTitle;

        .boldText {
          font-family: $fontWeightBold;
        }

        .regularText {
          font-family: $fontWeightRegular;
        }
      }
    }

    .contentContainer {
      display: flex;
      flex-direction: column;
      gap: 17px;

      .infoRowContainer {
        display: flex;
        gap: 17px;

        .icon {
          width: 20px;
          height: 20px;
          color: $primaryPurple;
        }

        .text {
          width: calc(100% - 20px - 17px);
          color: $fontSecondary;
          font-family: $fontWeightRegular;
          font-size: $fontMedium;
        }
      }
    }
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}