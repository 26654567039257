@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.select-provider-container {
  margin-top: 21px;
  width: 100%;
  overflow: auto;

  .providers-list-container {
    padding-left: 28px;
    padding-right: 34px;
    position: relative;
    display: flex;
    flex-direction: column;

    .provider-input {
      background: url("../../../assets/svg/search-icon.svg") top 50% left 20px no-repeat;
      padding-left: 44px;
    }

    .select-provider {
      font-family: $fontWeightSemiBold;
      font-size: $fontLarge;
      line-height: 26px;
      letter-spacing: -0.01em;
      color: $fontPrimary;

      .select-provider-text {
        margin-top: 0;
        margin-bottom: 10px;
        padding-top: 5px;
      }
    }

    .provider-list {
      padding: 0;
    }

    .provider-list-item {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 24px 0 24px 16px;
      border-top: 1px solid $borderGray;
      cursor: pointer;
      font-size: $fontSemiLarge;
      font-family: $fontWeightSemiBold;
      color: $fontPrimary;
      line-height: 25px;
      gap: 9px;

      .provider-list-icons {
        height: 40px;
        width: 40px;
      }

      &:first-child {
        border-top: none;
      }
    }

    .provider-list-item-focused {
      background-color: $borderGray;
    }

    .wallet-connect-button-container {
      display: flex;
      justify-content: center;
      padding-bottom: 26px;
    }
  }
}

.list-title {
  font-size: $fontVerySmall;
  font-family: $fontWeightSemiBold;
  margin-top: 17px;
  color: $fontSecondary;
  letter-spacing: 0.14em;
}