@import 'src/styles/variables/typography';

.calendar-wrapper {
  width: 100%;
  height: 100%;
}

.frequencies-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: auto;

  .frequency-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 28px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.20);
    cursor: pointer;
    font-family: $fontWeightSemiBold;
  }
}