@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 62px 250px 110px 250px;
  width: 1748px;
  height: 2480px;
  background-image: url("../../assets/certificate-assets/certificateBackground.jpg");
  background-size: contain;
  background-repeat: no-repeat;

  .qr-code-container {
    display: flex;
    padding: 38px 90px 41px 80px;
    align-items: center;
    gap: 48px;
    margin-top: 616px;
    border-radius: 28px;
    background: #FFF;
    z-index: 2;

    .qr-description {
      display: flex;
      align-items: center;
      gap: 12px;

      .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .important-info {
          color: $titleColor;
          font-size: 37px;
          font-family: $fontWeightSemiBold;
          letter-spacing: -0.372px;
        }
        .info-text {
          color: #888;
          font-size: 32px;
          font-family: $fontWeightRegular;
          line-height: 41.85px;
        }
      }

      .yellow-notification {
        width: 264px;
        height: 264px;
      }
    }

    .qr-code {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;

      .qr-info {
        display: flex;
        align-items: center;
        gap: 12px;
        color: $fontPrimary;

        .info-icon{
          width: 27px;
          height: 27px;
        }

        .qr-text {
          font-size: 20px;
          font-family: $fontWeightRegular;
          letter-spacing: -0.193px;
        }
      }
    }
  }

  .mnemonic-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    margin-top: 83px;
    width: 100%;

    .mnemonic-title {
      color: $titleColor;
      font-size: 37px;
      font-family: $fontWeightSemiBold;
      letter-spacing: -0.372px;
    }

    .words-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(8, 1fr);
      grid-auto-flow: column;
      grid-gap: 16px 56px;
      width: 100%;

        .word-element {
          display: flex;
          padding: 22px 54px 26px 94px;
          align-items: center;
          gap: 18px;
          border-radius: 30px;
          border: 2px solid #B7B7B7;
          background: $backgroundWhite;
        }

        .word-number {
          color: $fontPrimary;
          font-size: 38px;
          font-family: $fontWeightBold;
          letter-spacing: 3.861px;
          text-transform: uppercase;
        }

        .word-text {
          color: $fontPrimary;
          font-size: 38px;
          font-family: $fontWeightRegular;
        }
    }

    .footer {
      display: flex;
      align-items: center;
      gap: 46px;

      .blockmate-logo-icon {
        width: 256px;
        height: 54px;
      }

      .footerText {
        color: #888;
        font-size: 32px;
        font-family: $fontWeightRegular;
        line-height: 41.85px;
      }
    }
  }
}

.generate-certificate-button {
  background-color: $mainYellow;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 18px;
  letter-spacing: 1px;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: $fontSize;
  color: $fontPrimary;
  cursor: pointer;
  transition: background-color 200ms ease-in;
  min-width: 300px;
  padding: 14px 28px;
  margin-bottom: 33px;
  font-family: $fontWeightSemiBold;
  text-decoration: none;

  .download-icon {
    margin-right: 10px;
    width: 23px;
    height: 23px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $secondaryDisabledGrey;
  }

  .download-icon {
    margin-right: 9px;
  }
}