@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/typography';

.container {
  display: flex;
  gap: 16px;

  .iconContainer {
    width: 20px;
    height: 20px;
    color: $primaryPurple;
    margin-top: 4px;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;

    .title {
      color: $fontPrimary;
      font-family: $fontWeightSemiBold;
      font-size: $fontLarge;
    }

    .description {
      color: $fontSecondary;
      font-family: $fontWeightRegular;
      font-size: $fontSize;
    }
  }
}