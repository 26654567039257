@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.saving-certificate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .graphic {
    margin-left: 71px;
  }

  .button {
    display: flex;
    justify-content: center;
  }

  .certificate-title {
    font-family: $fontWeightSemiBold;
    font-size: $fontVeryBig;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: $fontPrimary;
    text-align: center;
  }

  .certificate-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0 25px;
  }

  .certificate-description {
    font-size: $fontMedium;
    line-height: 23px;
    letter-spacing: -0.16px;
    color: $fontSecondary;
    text-align: center;
    font-family: $fontWeightRegular;
  }
}