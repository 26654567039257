@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.form-input {
  margin-top: 20px;

  &.alert {
    margin-top: 14px;
  }
}

.provider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 16px 30px 0 30px;

  .provider-name {
    font-family: $fontWeightSemiBold;
    font-size: $fontLarge;
    line-height: 26px;
    color: $fontPrimary;
    padding-bottom: 10px;
  }

  .provider-description {
    font-family: $fontWeightRegular;
    font-size: $fontMedium;
    line-height: 24px;
    text-align: center;
    color: $fontSecondary;
    margin-bottom: 6px;
    letter-spacing: -0.01em;

    .provider-name-nested {
      font-family: $fontWeightSemiBold;
      color: $fontPrimary;
    }
  }

  .input-alert {
    font-size: $fontSize;
    font-family: $fontWeightRegular;
    color: $fontError;
    margin-top: 14px;
    margin-right: auto;
  }

  .no-alert {
    display: none;
  }

  .connect-input {
    margin-top: 14px;
  }
}

.capitalize {
  text-transform: capitalize;
}

