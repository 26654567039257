@import "src/styles/variables/typography";

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding-left: 23px;
  padding-right: 29px;

  button {
    cursor: pointer;
  }

  .provider-logo {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: $fontMedium;
    font-family: $fontWeightSemiBold;

    img {
      height: 28px;
    }
  }
}