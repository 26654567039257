@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.saving-success {
  justify-content: space-between;

  .success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 27px;
  }

  .confirm-button {
    margin-bottom: 33px;
  }

  .success-logo {
    margin-bottom: 18px;
  }

  .success-title {
    font-family: $fontWeightSemiBold;
    font-size: $fontVeryBig;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: $fontPrimary;
    text-align: center;
  }

  .success-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0 35px;
  }

  .success-description {
    font-size: $fontMedium;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: $fontSecondary;
    text-align: center;
    font-family: $fontWeightRegular;
  }

  .closing-counter {
    background-color: $lightGreen;
    border-radius: 8px;
    height: 30px;
    margin-top: 18px;
    display: flex;
    align-items: center;
  }

  .counter-text {
    color: $fontGreen;
    font-family: $fontWeightSemiBold;
    font-size: $fontVerySmall;
    line-height: 18px;
    letter-spacing: 0.02em;
    padding: 0 10px;
    width: 205px;
  }
}