@import 'src/styles/variables/colors';

.loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-container {
  position: absolute;
  background: rgba(128, 128, 128, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modal-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 360px;
  height: 610px;
  background: $backgroundWhite;
  border-radius: 20px;
  overflow: hidden;
}

.content-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 71px;
  padding-right: 72px;
  text-align: center;
}