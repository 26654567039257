@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.connection-type-selector-container {
  margin-top: 32px;
}

.connect-option {
  padding: 26px;
  text-align: center;
}

.connect-option-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.connect-option-info {
  margin-top: 18px;
  margin-bottom: 18px;
}

.paragraph-explain {
  display: flex;
  justify-content: center;
  color: $fontSecondary;
  font-size: $fontMedium;
  font-weight: $fontWeightRegular;
  margin-bottom: 18px;
}

.or-line-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.or-line-text {
  margin-left: 8px;
  margin-right: 8px;
  font-weight: $fontWeightSemiBold;
}

.or-line-part {
  display: flex;
  height: 0;
  width: calc(25% - 20px);
  margin-top: auto;
  margin-bottom: auto;
  border-top: 1px solid $lightGrey;
}

.advanced-feature-badge {
  display: inline-block;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: $mainYellow;

  color: $fontPrimary;
  font-size: 9px;
  font-style: normal;
  font-weight: $fontWeightSemiBold;
  line-height: normal;
  letter-spacing: 0.9px;
}