@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.modal-footer {
  display: flex;
  flex-direction: column;
  font-family: $fontWeightRegular;
  font-size: $fontSize;
  line-height: 140%;
  color: $fontPrimary;
  text-align: center;
  margin: auto 36px 30px;
}