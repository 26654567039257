@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/typography';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 100%;

  .topContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .statusContainer {
      display: flex;
      justify-content: center;
    }

    .heading {
      color: $fontPrimary;
      text-align: center;
      font-family: $fontWeightSemiBold;
      font-size: $fontVeryBig;
    }

    .descriptionContainer {
      display: flex;
      justify-content: center;

      .description {
        color: $fontSecondary;
        text-align: center;
        font-family: $fontWeightRegular;
        font-size: $fontMedium;
        width: 80%;
      }
    }

    .closingMessageOuterContainer {
      display: flex;
      justify-content: center;

      .closingMessageContainer {
        display: inline-block;
        padding: 6px 14px 8px 14px;
        border-radius: 8px;

        .text {
          font-family: $fontWeightSemiBold;
          font-size: $fontVerySmall;
        }
      }

      .success {
        background: $lightGreen;
        color: $fontGreen;
      }

      .error {
        background: $errorBackground;
        color: $fontError;
      }
    }
  }

  .bottomContainer {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}