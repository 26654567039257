@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/typography';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  overflow-y: auto;
  padding-inline: 10px;

  .topSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 20px;

    .payWithSection {
      display: flex;
      gap: 6px;
      width: 100%;
      justify-content: center;
    }

    .heading {
      display: flex;
      justify-content: center;
      width: 100%;
      color: $fontPrimary;
      font-size: $fontLarge;
      font-family: $fontWeightBold;
    }

    .summaryContainer {
      display: flex;
      flex-direction: column;
      gap: 14px;

      .divider {
        width: 100%;
        height: 0;
        border-top: 1px solid $borderLightGrey;
      }

      .summaryLineContainer {
        display: flex;
        justify-content: space-between;
        color: $fontPrimary;
        font-size: $fontSize;
        font-family: $fontWeightRegular;

        .rowTitle {
          color: $fontSecondary;
        }

        .radioOptions {
          display: flex;
          flex-direction: column;
          justify-content: left;

          .optionContainer {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .radioItemContainer {
              display: flex;
              gap: 4px;

              .disabled {
                color: $fontSecondary;
                cursor: not-allowed;
              }
            }

            .minAmountWarning {
              color: $fontError;
              margin-bottom: 12px;
              display: flex;
              gap: 4px;

              .minAmountText {
                max-width: 140px;
                font-size: $fontSize;
              }

              .icon {
                width: 14px;
                height: 14px;
              }
            }
          }
        }

        .providerContainer {
          display: flex;
          gap: 4px;

          .iconContainer {
            width: 20px;
            height: 20px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .totalLineContainer {
        display: flex;
        justify-content: space-between;
        color: $fontPrimary;
        font-size: $fontSize;
        font-family: $fontWeightSemiBold;
      }
    }
  }

  .bottomSection {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .feesHeading {
      color: $fontPrimary;
      font-family: $fontWeightSemiBold;
      font-size: $fontSize;
    }

    .feeInfoLineContainer {
      display: flex;
      justify-content: space-between;
      color: $fontSecondary;
      font-family: $fontWeightRegular;
      font-size: $fontSize;
    }
  }
}

.purpleIcon {
  width: 20px;
  height: 20px;
  color: $primaryPurple;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
