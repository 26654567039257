@import 'src/styles/variables/colors';

.counter {
  background-color: $mainGreen;
  border-radius: 8px;
  padding: 6px 14px;
  width: 92px;
  position: absolute;
  right: 19px;
  top: 14px;
  font-size: 9px;
}