@import 'src/styles/variables/colors.scss';
@import 'src/styles/variables/typography.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 30px;
  color: $mainBlack;

  .topPart {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    .title {
      display: flex;
      width: 100%;
      justify-content: center;
      text-align: center;
      font-family: $fontWeightBold;
      font-size: $fontLarge;
    }

    .description {
      color: $mainGrey;
      font-size: $fontMedium;
      text-align: center;
    }
  }

  .bottomPart {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
}