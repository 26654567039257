@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/typography';

.container {
  display: flex;
  gap: 6px;
  align-items: center;

  .icon {
    width: 28px;
    height: 28px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .name {
    color: $fontPrimary;
    font-family: $fontWeightSemiBold;
    font-size: $fontMedium;
  }
}