@import 'src/styles/variables/colors.scss';
@import 'src/styles/variables/typography.scss';

.wallet-connect-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-small {
  margin-right: 8px;
}

.wallet-connect-logo {
  width: 34px;
  color: $walletConnectBlue;
}

.center-horizontal {
  display: flex;
  justify-content: center;
}

.message-heading {
  color: $titleColor;
  text-align: center;
  font-size: $fontVeryBig;
  font-style: normal;
  font-family: $fontWeightSemiBold;
  line-height: 29px;
  letter-spacing: -0.23px;
  margin-top: 18px;
}

.success-badge {
  display: inline-block;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: $lightGreen;
  color: $fontGreen;
  margin-top: 12px;
}

.message-info {
  color: $fontSecondary;
  text-align: center;
  font-size: $fontMedium;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
}

.message-button-container {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

.connection-message-container {
  margin-top: 46px;
}
