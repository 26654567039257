/* Reset */

@font-face {
  font-family: helveticaNowRegular;
  src: url('../src/styles/fonts/helvetica-now-font-regular/font.woff');
}

* {
  box-sizing: border-box;
  margin: 0;
}

html, body {
  height: 100%;
  font-family: helveticaNowRegular, sans-serif;
}

button {
  cursor: pointer;
}

//scrollbar
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #F5F5F5;
  position: absolute;
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #202020;
}