@import '../../styles/variables/colors';
@import '../../styles/variables/typography';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .contentContainer {
    display: flex;
    flex-direction: column;;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 32px;

    .logoContainer {
      width: 255px;
      height: 60px;
      color: $fontPrimary;
      opacity: 0.5;
    }

    .title {
      font-size: $fontGigantic;
      font-family: $fontWeightBold;
    }

    .depositTypeSelect {
      height: 45px;
    }

    .paymentStatusContainer {
      display: flex;
      border: 2px solid $titleColor;
      border-radius: 10px;
      padding: 20px;
      margin-top: 20px;
      align-items: center;
      gap: 10px;
      font-size: 20px;
      color: $fontSecondary;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      .status {
        font-family: $fontWeightBold;
      }
    }

    .explanation {
      font-size: $fontVeryBig;
      color: $fontSecondary;
      max-width: 50%;

      .alertText {
        color: $errorColor;
        font-family: $fontWeightBold;
      }
    }

    .payNowFormContainer {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .restartProcess {
      color: $fontSecondary;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        color: $primaryPurple;
      }
    }
  }
}

.allAlertsContainer {
  width: 100%;

  .errorsContainer {
    display: flex;
    flex-direction: column;

    .error {
      width: 100%;
    }
  }
}