@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/typography';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 100%;

  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;

    .title {
      color: $titleColor;
      text-align: center;
      font-family: $fontWeightSemiBold;
      font-size: $fontVeryBig;
    }

    .message {
      max-width: 260px;
      text-align: center;
      color: $fontSecondary;
      font-family: $fontWeightRegular;
      font-size: $fontMedium;
    }

    .linkedAccountInfoContainer {
      display: flex;
      padding: 6px 14px 8px 14px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 8px;
      background: $lightGreen;
      max-width: 90%;
      color: $fontGreen;
      font-family: $fontWeightSemiBold;
      font-size: $fontVerySmall;
    }
  }
}