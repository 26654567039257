@import 'src/styles/variables/typography';
@import 'src/styles/variables/colors';

.select {
  position: relative;

  .select-input-container {
    display: flex;
    min-width: 300px;
    max-height: 51px;
    border: 2px solid $lightGrey;
    border-radius: 10px;
    font-size: $fontSize;
    line-height: 140%;
    padding: 14px 20px 14px 20px;
    cursor: pointer;

    &.selected {
      border: 2px solid $mainPurple;
    }

    .dash-icon {
      transform: rotate(-90deg);

      &.dash-icon-reverse {
        transform: rotate(90deg);
      }

      path {
        stroke: $mainBlack;
      }
    }

    .select-input {
      width: 100%;
      font-family: $fontWeightRegular;
      font-size: $fontSize;
      border: none;
      cursor: pointer;
      background: transparent;

      &:focus {
        outline: none;
      }
    }

    &.select-disabled {
      cursor: not-allowed;

      .select-input {
        cursor: not-allowed;
      }

      .dash-icon {
        display: none;
      }
    }
  }

  .select-dropdown {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px 0;
    border: 2px solid $lightGrey;
    border-radius: 0 0 10px 10px;
    background: #FAFAFA;
    width: 100%;
    max-height: 250px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    gap: 10px;
    z-index: 2;
    overflow-y: auto;

    .dropdown-item {
      display: flex;
      gap: 12px;
      font-size: $fontSize;
      font-family: $fontWeightSemiBold;
      line-height: 140%;
      letter-spacing: 0.01em;
      cursor: pointer;
      padding: 0 10px;

      &:hover {
        background: #dfecff;
      }
    }
  }
}
