@import '../../styles/variables/colors';
@import '../../styles/variables/typography';

.bug-icon {
  margin-bottom: 17px;
}

.validation-error-code {
  font-family: $fontWeightSemiBold;
  font-size: $fontLarge;
  line-height: 26px;
  color: $fontPrimary;
  margin-bottom: 4px;
}

.validation-error-text {
  font-family: $fontWeightRegular;
  font-size: $fontSize;
  line-height: 24px;
  color: $fontSecondary;
}

.error-documentation {
  color: $fontPurple;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.validation-error-container {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-bottom: 45px;
}

.validation-error-close-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  .validation-error-close-icon {
    margin-top: 28px;
    margin-right: 34px;
    cursor: pointer;
  }
}