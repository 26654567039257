.crypto-savings-container {
  .modal-intro-header {
    margin-bottom: 25px;
    margin-top: 35px;

    .modal-intro-logo {
      margin-bottom: 0;
    }

    .modal-intro-title {
      margin-top: 18px;
      padding: 0 50px;
    }

    .exit {
      position: absolute;
      right: 29px;
      top: 25px;
      padding: 0;
      background: none;
      border: none;
    }
  }

  .modal-footer {
    margin-bottom: 30px;
  }
}