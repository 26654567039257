@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/typography';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-inline: 30px;

  .topContainer {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .heading {
      display: flex;
      justify-content: center;
      color: $fontPrimary;
      font-family: $fontWeightBold;
      font-size: $fontLarge;
    }

    .infoLineContainer {
      display: flex;
      gap: 16px;

      .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background: $lightPurple;
        margin-top: 2px;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 12px;
          height: 12px;
          color: $primaryPurple;
        }
      }

      .textContainer {
        color: $fontPrimary;
        font-size: $fontMedium;
        font-family: $fontWeightRegular;
        width: calc(100% - 24px - 16px);
      }
    }
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    gap: 17px;
    border-top: 1px solid $lightGrey;
    font-family: $fontWeightRegular;

    .infoSummary {
      color: $fontPrimary;
      font-size: $fontSize;
      text-align: center;
      margin-top: 17px;
    }

    .detailedExplanation {
      color: $fontSecondary;
      text-align: center;
      font-size: $fontTiny;
    }
  }
}

.bold {
  font-family: $fontWeightBold;
}