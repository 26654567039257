@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/typography';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-inline: 30px;

  .topContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    align-items: center;

    .title {
      color: $titleColor;
      text-align: center;
      font-family: $fontWeightSemiBold;
      font-size: $fontVeryBig;
    }

    .description {
      color: $fontSecondary;
      text-align: center;
      font-family: $fontWeightRegular;
      font-size: $fontMedium;
      width: 80%;
    }

    .secondFactorInput {
      height: 40px;
      border-radius: 3px;
      background: $backgroundWhite;
      text-align: center;
    }
  }

  .bottomContainer {
    display: flex;
    width: 100%;
    justify-content: center;
  }
};