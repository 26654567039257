@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/typography';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 30px;

  .headerContainer {
    max-width: 162px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .imageSection {

      .logoImg {
        width: 100%;
        height: 100%;
      }
    }

    .textSection {
      color: $fontPrimary;
      text-align: center;
      font-family: $fontWeightBold;
      font-size: $fontTitle;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}