@import '../../../../styles/variables/colors';

.container {
  display: inline-block;
  max-height: 80px;
  overflow-y: auto;
  min-height: 30px;

  .error {
    color: $errorColor;
  }
}