@import '../../../../styles/variables/colors';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  .logoContainer {
    width: 50px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .lineWithCircleContainer {
    color: $lightGrey;
    width: 24px;
    height: 24px;
  }
}