@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.main-input {
  min-width: 300px;
  border: 2px solid $lightGrey;
  border-radius: 10px;
  font-family: $fontWeightRegular;
  font-size: $fontSize;
  line-height: 140%;
  padding: 14px 0 14px 20px;
  margin-top: 0;

  :read-only {
    cursor: not-allowed;
  }
}

.main-input::placeholder {
  color: $fontSecondary;
}
