@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.or-main-container {
  display: flex;
  justify-content: center;

  .or-line-container {
    display: flex;
    font-family: $fontWeightSemiBold;
    font-size: $fontVerySmall;
    color: $fontPrimary;
    position: relative;
    width: 50%;

    .or-line-text {
      background: $backgroundWhite;
      padding: 4px;
      z-index: 1;
      width: 24px;
    }

    &:after {
      content: '';
      height: 1px;
      width: 100%;
      background-color: $lightGrey;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
    }
  }
}
