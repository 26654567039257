@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

.phone-modal {
  position: relative;

  .header {
    display: flex;
    justify-content: center;
    padding: 20px 28px 10px
  }

  .close {
    position: absolute;
    right: 30px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .blockmate-info-icon {
      width: 113px;
      height: 113px;
    }
  }

  .confirm {
    margin-top: 103px;

    .main-button {
      margin-top: 14px;
    }
  }

  .confirm-transfer {
    margin-top: 50px;

    .title {
      margin-top: 0;
    }

    .confirm-button {
      margin-top: 14px;
    }
  }

  .resend {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .link {
      line-height: 24px;
    }

    svg {
      width: 21px;
      height: 20px;
    }
  }

  .title {
    text-align: center;
    font-size: $fontLarge;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: $mainBlack;
    margin-top: 4px;
    padding: 0 60px;
    font-family: $fontWeightSemiBold;
  }

  .description {
    margin: 10px 32px 20px 36px;
    font-family: $fontWeightRegular;
    font-size: $fontMedium;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $mainGrey;
    text-align: center;
  }

  .provider-input {
    &::placeholder {
      color: $mainGrey;
    }
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
  }

  .with-counter {
    position: relative;
  }

  .info-box {
    min-width: 228px;
    max-width: 250px;
    margin-top: 20px;
    padding: 10px;
    font-size: $fontSize;
    line-height: 140%;
    letter-spacing: -0.01em;
    color: $mainPurple;
    background: $lightPurple;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    .ip-box {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }

  .logo-info {
    margin-top: 5px;

    svg {
        width: 114px;
        height: 114px;
    }
  }

  .content-margin-top {
    margin-top: 76px;
  }

  .input-error {
    border-color: $errorColor;
  }

  .error-text {
    font-family: $fontWeightRegular;
    height: 14px;
    margin: 2px 0 8px;
    font-size: $fontVerySmall;
    color: $errorColor;
  }

  .input-margin {
    margin-bottom: 10px;
  }
}

