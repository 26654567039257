@import '../../styles/variables/colors';
@import '../../styles/variables/typography';

.container {
  width: calc(100% - 16px); // 32px for margin
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 16px;
  color: $mainWhite;
  margin: 8px;

  .description {
    font-family: $fontWeightBold;
  }

  .closeButton {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.success {
  background: $successColor;
}

.error {
  background: $errorColor;
}

.info {
  background: $infoColor;
}